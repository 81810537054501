import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Background from './Login/img2.jpg'
import NeuroLogo from './Login/logo-light.PNG'

const styles = theme => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(' + Background + ')',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '220px',
		height: '100px',
	},
	logo: {
		width: '220px',
		height: 'auto',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	formControl: {
		margin: theme.spacing(3),
	},
	group: {
		margin: theme.spacing(1, 0),
	},
})

class Finished extends React.Component {
	redirect() {
		window.location.replace('https://plataforma.neuropsicologia.online')
	}
	render() {
		const { classes } = this.props
		return (
			<Grid container component='main' className={classes.root}>
				<CssBaseline />
				<Grid item xs={false} sm={4} md={7} className={classes.image} />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<div className={classes.paper}>
						<div className={classes.avatar}>
							<img src={NeuroLogo} alt='' className={classes.logo} />
						</div>
						<Typography component='h1' variant='h5'>
							Muito obrigado!
						</Typography>
						<br />
						<Typography component='p' variant='h5' style={{ fontSize: '20px' }}>
							{this.props.location.query.address
								? 'Muito obrigado pela atualização do seu cadastro. Clique no botão abaixo para acessar a plataforma.'
								: 'Caso o seu pagamento seja via cartão de crédito, confira se você recebeu em seu e-mail uma confirmação do pagamento. Caso sim, você já pode acessar os seus cursos. Clique no botão abaixo para acessar a plataforma!'}
						</Typography>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							onClick={this.redirect}
							className={classes.submit}
						>
							Entrar
						</Button>
					</div>
				</Grid>
			</Grid>
		)
	}
}

Finished.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Finished)
