import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import queryString from 'query-string'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
} from '@material-ui/core'
import Background from './img2.jpg'
import NeuroLogo from './logo-light.PNG'
import { Link as RouterLink } from 'react-router-dom'
import { usePayment } from '../../store'
import { API_URL } from '../util/constants'

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(' + Background + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '220px',
    height: '100px',
  },
  logo: {
    width: '220px',
    height: 'auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

const Login = ({ classes, location, history }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { $payment, setField, setUserData, setSearchData } = usePayment()
  const {
    transaction,
    email,
    validateEmail,
    helperEmail,
    errorMessage,
  } = $payment

  useEffect(() => {
    if (location.search.length && !transaction.length) {
      const linkObject = queryString.parse(location.search)
      setSearchData(linkObject)
    }
    // eslint-disable-next-line
  }, [location.search, transaction.length])

  const submitForm = () => {
    setLoading(true)
    if (email === '') {
      setField('validateEmail', true)
      setField('helperEmail', 'Campo está em branco')
      setLoading(false)
      return
    } else {
      setField('validateEmail', false)
      setField('helperEmail', '')
      axios
        .get(`${API_URL}/user/` + email, {
          headers: {
            authorization: process.env.REACT_APP_APIKEY,
          },
        })
        .then(response => {
          const data = {
            first_name: response.data.data[0].first_name,
            last_name: response.data.data[0].last_name,
            id: response.data.data[0].id,
            cpf: response.data.data[0].custom_profile_fields[3].value,
            phone: response.data.data[0].custom_profile_fields[2].value,
            cep: response.data.address.add_cep,
            street: response.data.address.add_street,
            number: response.data.address.add_number,
            complement: response.data.address.add_complement,
            neighborhood: response.data.address.add_neighborhood,
            city: response.data.address.add_city,
            state: response.data.address.add_state,
            issub: response.data.address.usr_issub === 1,
          }
          setUserData(data)
          if (response.status === 200) {
            if (
              data.cpf === null ||
              data.cpf.length === 0 ||
              data.phone === null ||
              data.phone.length === 0 ||
              response.data.noAddress
            ) {
              history.push({
                pathname: '/pagamentos/completeregistration',
              })
            } else {
              history.push({
                pathname: '/pagamentos/payment',
              })
            }
          }
        })
        .catch(error => {
          setLoading(false)
          setModalOpen(true)
          setField(
            'errorMessage',
            'O e-mail não corresponde a nenhum usuário cadastrado'
          )
        })
    }
  }

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.avatar}>
            <img src={NeuroLogo} alt='' className={classes.logo} />
          </div>
          <Typography component='h1' variant='h5'>
            Caso já seja inscrito(a) na plataforma. Digite seu e-mail abaixo
          </Typography>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={email}
            onChange={e => setField('email', e.target.value)}
            id='email'
            label='Endereço de e-mail'
            name='email'
            autoComplete='email'
            autoFocus
            error={validateEmail}
            helperText={helperEmail}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            onClick={submitForm}
            className={classes.submit}
            disabled={isLoading}
          >
            PAGAR
          </Button>
          <Grid container>
            <Grid item>
              <RouterLink
                to={{
                  pathname: '/pagamentos/register',
                }}
              >
                {'Não tem uma conta? Inscreva-se'}
              </RouterLink>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Erro</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Login)
