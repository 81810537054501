import React from 'react'
import StateProviders from './lib/StateProviders'
import PropTypes from 'prop-types'

function Providers({ children }) {
	return <StateProviders>{children}</StateProviders>
}

Providers.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Providers
