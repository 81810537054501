import React, { useState, useEffect } from 'react'
import Cards from 'react-credit-cards'
import { TextField, Select, MenuItem, InputLabel } from '@material-ui/core'
import { dateMask } from '../util/dateMask'
import { usePayment } from '../../store'
import 'react-credit-cards/es/styles-compiled.css'

const CreditCardForm = ({ selectClassName }) => {
  const { $payment, setField } = usePayment()
  const {
    installmentsOptions,
    transaction,
    amount,
    card_number,
    card_name,
    card_expiry_date,
    card_installments,
    card_cvc,
  } = $payment

  const [focused, setFocused] = useState('name')
  const [installmentValue, setInstallmentValue] = useState(amount)

  useEffect(() => {
    if (Number(card_installments) === 1) {
      setInstallmentValue(amount)
    }
    //eslint-disable-next-line
  }, [amount])

  return (
    <>
      <Cards
        number={card_number}
        name={card_name}
        expiry={card_expiry_date}
        cvc={card_cvc}
        focused={focused}
        locale={{ valid: 'valido até' }}
        placeholders={{ name: 'SEU NOME AQUI' }}
      />
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        value={card_number}
        onChange={event => setField('card_number', event.target.value)}
        id='number'
        label='Número do cartão'
        name='number'
        autoComplete='credit-card'
        autoFocus
      />
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        value={card_name}
        onChange={event => setField('card_name', event.target.value)}
        id='name'
        label='Nome impresso no cartão'
        name='name'
        autoComplete='name'
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          value={dateMask(card_expiry_date)}
          onChange={event => setField('card_expiry_date', event.target.value)}
          id='expiry'
          label='Valido até'
          name='expiry'
          autoComplete='expiry-date'
          inputProps={{ maxLength: 5 }}
          style={{ width: '48%' }}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          value={card_cvc}
          onChange={event => setField('card_cvc', event.target.value)}
          onFocus={() => setFocused('cvc')}
          onBlur={() => setFocused('name')}
          id='cvc'
          label='Código de segurança'
          name='cvc'
          autoComplete='cvc'
          inputProps={{ maxLength: 4 }}
          style={{ width: '48%' }}
        />
      </div>
      {transaction === 'checkout' ? (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <InputLabel id='installment'>Parcelas</InputLabel>
          <Select
            labelId='installment'
            value={amount}
            renderValue={value =>
              `${card_installments} x R$${installmentValue / 100} - Total: R$${
                value / 100
              }`
            }
            onChange={event => {
              setField('amount', event.target.value[0])
              setField('card_installments', event.target.value[1])
              setInstallmentValue(event.target.value[2])
            }}
            id='installments'
            name='installments'
            className={selectClassName}
          >
            {installmentsOptions &&
              installmentsOptions.map((option, index) => (
                <MenuItem
                  value={[
                    option[1].amount,
                    option[0],
                    option[1].installment_amount,
                  ]}
                >
                  {`${option[0]} x R$${
                    option[1].installment_amount / 100
                  } - Total: R$${option[1].amount / 100}`}
                </MenuItem>
              ))}
          </Select>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default CreditCardForm
