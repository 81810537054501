import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Background from '../Login/img2.jpg'
import NeuroLogo from '../Login/logo-light.PNG'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CardMedia,
} from '@material-ui/core'
import { API_URL } from '../util/constants'

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(' + Background + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '220px',
    height: '100px',
  },
  logo: {
    width: '220px',
    height: 'auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },

  video: {
    height: '40%',
    width: '60%',
    position: 'absolute',
    top: '20%',
    left: '20%',
  },
})

class ConfirmCoupon extends React.Component {
  state = {
    modalOpen: false,
    dialogMessage: '',
    dialogTitle: '',
    redirect: false,
    hasLinkCoupon: true,
    coupon: '',
    validateCoupon: '',
    helperCoupon: '',
  }

  componentDidMount() {
    this.setState({ hasLinkCoupon: this.props.location.query.hasLinkCoupon })
  }

  submitForm = () => {
    const coupon = this.state.hasLinkCoupon
      ? this.props.location.query.coupon
      : this.state.coupon
    axios
      .post(
        `${API_URL}/coupons/useCoupon`,
        {
          id: this.props.location.query.id,
          code: coupon,
        },
        {
          headers: {
            authorization: process.env.REACT_APP_APIKEY,
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          this.setState({
            modalOpen: true,
            dialogMessage:
              'Cupom utilizado com sucesso. Você será redirecionado para a plataforma.',
            dialogTitle: 'SUCESSO',
            redirect: true,
          })
        }
      })
      .catch(error => {
        if (error.response.status === 443) {
          this.setState({
            modalOpen: true,
            dialogMessage: 'Cupom inválido.',
            dialogTitle: 'ERRO',
          })
        } else {
          this.setState({
            modalOpen: true,
            dialogMessage:
              'Ocorreu um erro ao processar o cupom. Tente novamente mais tarde',
            dialogTitle: 'ERRO',
          })
        }
      })
  }

  _handleModalClose = () => {
    this.setState({ modalOpen: false })
    if (this.state.redirect) {
      window.location.href =
        'https://plataforma.neuropsicologia.online/users/sign_in'
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container component='main' className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image}>
          <CardMedia
            component='iframe'
            image='https://www.youtube.com/embed/WApbMZvzKpI'
            title='Instruções Parceria'
            className={classes.video}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <div className={classes.avatar}>
              <img src={NeuroLogo} alt='' className={classes.logo} />
            </div>
            <Typography component='h1' variant='h5'>
              {this.state.hasLinkCoupon
                ? 'Você confirma o uso do cupom para o e-mail '
                : 'Antes de clicar em validar, veja se o e-mail está correto: '}
              {this.props.location.query.email}
              {this.state.hasLinkCoupon && '?'}
            </Typography>
            {!this.state.hasLinkCoupon && (
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                value={this.state.coupon}
                onChange={event =>
                  this.setState({ coupon: event.target.value })
                }
                id='coupon'
                label='Código do Cupom'
                name='coupon'
              />
            )}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              onClick={this.submitForm}
              className={classes.submit}
            >
              {this.state.hasLinkCoupon ? 'SIM' : 'VALIDAR'}
            </Button>
            {this.state.hasLinkCoupon ? (
              <>
                <Typography component='p' variant='body1'>
                  Ao clicar no botão, o curso será liberado automaticamente na
                  sua conta (que você deverá acessar com o e-mail acima) e o
                  cupom será gasto <strong>IMEDIATAMENTE.</strong>
                </Typography>
                <Typography component='h1' variant='body1'>
                  <strong>O uso deste cupom é único.</strong>
                </Typography>
              </>
            ) : (
              <Typography component='p' variant='body1'>
                Ao clicar no botão de liberação, o curso será distribuído
                automaticamente na sua conta (que você deverá acessar com o
                e-mail acima) e{' '}
                <strong>o cupom não poderá ser mais utilizado.</strong>
              </Typography>
            )}
          </div>
        </Grid>
        <Dialog open={this.state.modalOpen} onClose={this._handleModalClose}>
          <DialogTitle>{this.state.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.dialogMessage}</DialogContentText>
          </DialogContent>
        </Dialog>
      </Grid>
    )
  }
}

ConfirmCoupon.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ConfirmCoupon)
