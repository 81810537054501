import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import queryString from 'query-string'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { Link as RouterLink } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Background from './img2.jpg'
import NeuroLogo from './logo-light.PNG'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CardMedia,
} from '@material-ui/core'
import { API_URL } from '../util/constants'

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(' + Background + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '220px',
    height: '100px',
  },
  logo: {
    width: '220px',
    height: 'auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  firstText: {
    fontSize: '18px',
    marginBottom: 5,
    textAlign: 'left',
  },
  video: {
    height: '40%',
    width: '60%',
    position: 'absolute',
    top: '20%',
    left: '20%',
  },
})

class CouponLogin extends React.Component {
  state = {
    email: '',
    validateEmail: false,
    helperEmail: '',
    modalOpen: false,
    errorMessage: '',
    courseName: '',
    linkObject: {},
    isLoading: false,
    hasLinkCoupon: true,
  }

  componentDidMount = () => {
    if (!(this.props.location.search.length === 0)) {
      const linkObject = queryString.parse(this.props.location.search)
      if ('c' in linkObject) {
        this.setState({
          linkObject: queryString.parse(this.props.location.search),
        })
        return
      }
      this.setState({ hasLinkCoupon: false })
    } else if ('query' in this.props.location) {
      this.setState({ linkObject: this.props.location.query })
    } else this.setState({ hasLinkCoupon: false })
  }

  componentDidUpdate = () => {
    if (this.state.linkObject !== undefined) {
      if (this.state.linkObject.c && this.state.courseName === '') {
        axios
          .get(
            `${API_URL}/coupons/checkCouponCourse/${this.state.linkObject.c}`,
            {
              headers: {
                authorization: process.env.REACT_APP_APIKEY,
              },
            }
          )
          .then(response => {
            if (response.status === 200) {
              this.setState({ courseName: response.data.course })
            }
          })
          .catch(error => {
            console.log(error)
            this.setState({ courseName: 'CUPOM INVÁLIDO' })
          })
      }
    }
  }

  _handleEmail = event => {
    this.setState({ email: event.target.value })
  }

  submitForm = () => {
    this.setState({ isLoading: true })
    if (this.state.email === '') {
      this.setState({
        validateEmail: true,
        helperEmail: 'Campo está em branco',
      })
      this.setState({ isLoading: false })
      return
    } else {
      this.setState({ validateEmail: false, helperEmail: '' })
      axios
        .get(`${API_URL}/user/` + this.state.email, {
          headers: {
            authorization: process.env.REACT_APP_APIKEY,
          },
        })
        .then(response => {
          let data = {
            first_name: response.data.data[0].first_name,
            last_name: response.data.data[0].last_name,
            email: this.state.email,
            id: response.data.data[0].id,
            coupon: this.state.linkObject.c,
            hasLinkCoupon: this.state.hasLinkCoupon,
            cpf: response.data.data[0].custom_profile_fields[3].value,
            phone: response.data.data[0].custom_profile_fields[2].value,
          }
          if (response.status === 200) {
            if (
              data.cpf === null ||
              data.cpf.length === 0 ||
              data.phone === null ||
              data.phone.length === 0 ||
              response.data.noAddress
            ) {
              this.props.history.push({
                pathname: '/pagamentos/coupon-completeregistration',
                query: data,
              })
            } else {
              this.props.history.push({
                pathname: '/pagamentos/confirm-coupon',
                query: data,
              })
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isLoading: false,
            modalOpen: true,
            errorMessage:
              'O e-mail não corresponde a nenhum usuário cadastrado',
          })
        })
    }
  }

  _handleModalClose = () => {
    this.setState({ modalOpen: false })
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container component='main' className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image}>
          <CardMedia
            component='iframe'
            image='https://www.youtube.com/embed/WApbMZvzKpI'
            title='Instruções Parceria'
            className={classes.video}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <div className={classes.avatar}>
              <img src={NeuroLogo} alt='' className={classes.logo} />
            </div>
            <Typography
              component='h3'
              variant='h5'
              className={classes.firstText}
            >
              {this.state.hasLinkCoupon ? (
                <>
                  Parabéns! Se você está nesta página você veio a partir de
                  algum de nossos parceiros. <br />
                  <br />
                  Para conhecer um pouco mais como funciona a Formação
                  Continuada em Neuropsicologia e como será sua "estadia" aqui
                  em nossa plataforma de cursos online basta assistir ao vídeo
                  do lado.
                </>
              ) : (
                <>
                  Olá! Seja bem-vindo(a) ao primeiro passo para liberação do seu
                  curso online aqui na Formação Continuada em Neuropsicologia.
                  <br /> <br />O processo é bem simples, e se você ainda não
                  conhece como funciona nossa plataforma o vídeo ao lado poderá
                  te ajudar. <br /> <br /> Se você já possui cadastro na nossa
                  plataforma basta inserir seu e-mail abaixo. O cupom vai
                  liberar o curso para este e-mail. Caso ainda não tenha uma
                  conta, clique em “Não tem uma conta? Inscreva-se”, para
                  criá-la. Na próxima tela você deverá inserir o seu cupom.
                </>
              )}
            </Typography>
            <Typography
              component='h3'
              variant='h5'
              className={classes.firstText}
            >
              {this.state.courseName === 'CUPOM INVÁLIDO' ||
              !this.state.hasLinkCoupon
                ? ''
                : `Este link vai liberar o seguinte curso para você: ${this.state.courseName}`}
            </Typography>
            {this.state.hasLinkCoupon && (
              <Typography
                component='h3'
                variant='h5'
                className={classes.firstText}
              >
                Se você já tem cadastro com nossa plataforma, por favor digite
                seu e-mail abaixo. Caso ainda não possua, será necessário criar
                uma nova conta.
              </Typography>
            )}
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              value={this.state.email}
              onChange={this._handleEmail}
              id='email'
              label='Endereço de e-mail'
              name='email'
              autoComplete='email'
              autoFocus
              error={this.state.validateEmail}
              helperText={this.state.helperEmail}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              disabled={
                this.state.courseName === 'CUPOM INVÁLIDO' ||
                this.state.isLoading
              }
              onClick={this.submitForm}
              className={classes.submit}
            >
              Continuar
            </Button>

            {this.state.courseName !== 'CUPOM INVÁLIDO' ? (
              <Grid container>
                <Grid item>
                  <RouterLink
                    to={{
                      pathname: '/pagamentos/coupon-register',
                      query: {
                        linkObject: this.state.linkObject,
                        hasLinkCoupon: this.state.hasLinkCoupon,
                      },
                    }}
                  >
                    {'Não tem uma conta? Inscreva-se'}
                  </RouterLink>
                </Grid>
              </Grid>
            ) : (
              <Typography
                component='h3'
                variant='h5'
                className={classes.firstText}
              >
                <strong>
                  O seu cupom é inválido. Entre em contato no e-mail
                  contato@neuropsicologia.online ou clique no chat abaixo para
                  conversar com nossa equipe.
                </strong>
              </Typography>
            )}
          </div>
        </Grid>
        <Dialog open={this.state.modalOpen} onClose={this._handleModalClose}>
          <DialogTitle>Erro</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.errorMessage}</DialogContentText>
          </DialogContent>
        </Dialog>
      </Grid>
    )
  }
}

CouponLogin.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CouponLogin)
