import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import LoginRefactor from './Login/LoginRefactor'
import CouponLogin from './Login/CouponLogin'
import RegisterRefactor from './Register/RegisterRefactor'
import CouponRegister from './Register/CouponRegister'
import CompleteRegistrationRefactor from './Register/CompleteRegistrationRefactor'
import CouponCompleteRegistration from './Register/CouponCompleteRegistration'
import PaymentRefactor from './Payment/PaymentRefactor'
import ConfirmCoupon from './Payment/ConfirmCoupon'
import Finished from './Finished'
import RegisterEnroll from './Register/RegisterEnroll'
import LoginAddress from './Login/LoginAddress'

const App = () => {
	return (
		<React.Fragment>
			<Router>
				<Route path='/pagamentos' exact component={LoginRefactor} />
				<Route path='/pagamentos/register' component={RegisterRefactor} />
				<Route path='/pagamentos/payment' component={PaymentRefactor} />
				<Route
					path='/pagamentos/completeregistration'
					component={CompleteRegistrationRefactor}
				/>
				<Route path='/pagamentos/coupon-login' component={CouponLogin} />
				<Route path='/pagamentos/coupon-register' component={CouponRegister} />
				<Route
					path='/pagamentos/coupon-completeregistration'
					component={CouponCompleteRegistration}
				/>
				<Route path='/pagamentos/confirm-coupon' component={ConfirmCoupon} />
				<Route path='/pagamentos/finished' component={Finished} />
				<Route path='/pagamentos/register-enroll' component={RegisterEnroll} />
				<Route path='/pagamentos/login-address' component={LoginAddress} />
			</Router>
		</React.Fragment>
	)
}

export default App
