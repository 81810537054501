import { useState, useEffect } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import paymentActions from './actions'
import paymentSelectors from './selectors'

// Store para armazenar os dados globais da aplicação

const initialState = {
  email: '',
  email2: '',
  validateEmail: false,
  helperEmail: '',
  errorMessage: '',
  transaction: '',
  product_id: 0,
  product_name: '',
  amount: '',
  first_name: '',
  last_name: '',
  password: '',
  password2: '',
  where_met: '',
  id: 0,
  cpf: '',
  phone: '',
  cep: '',
  street: '',
  number: 0,
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
  coupon: '',
  method: 'credit-card',
  card_number: '',
  card_cvc: '',
  card_name: '',
  card_expiry_date: '',
  card_installments: 1,
  installmentsOptions: [],
  couponConfirmed: false,
  issub: false,
  partnerCode: 0,
}

const PaymentStore = useCreateStore(() => {
  const [$payment, setPayment] = useState(initialState)
  const actions = paymentActions(setPayment)
  const selectors = paymentSelectors($payment)

  useEffect(() => {}, [])

  return { $payment, ...actions, ...selectors }
})

export const usePayment = () => PaymentStore()
export const PaymentContext = PaymentStore.Context
export const PaymentProvider = PaymentStore.Provider
