import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Background from '../Login/img2.jpg'
import NeuroLogo from '../Login/logo-light.PNG'
import courseHash from '../util/courseHash'
import { phoneMask } from '../util/PhoneMask'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from '@material-ui/core'
import queryString from 'query-string'
import axios from 'axios'
import { API_URL } from '../util/constants'

const styles = theme => ({
	root: {
		height: '100vh',
	},

	addressDiv: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	image: {
		backgroundImage: 'url(' + Background + ')',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '220px',
		height: '100px',
	},
	logo: {
		width: '220px',
		height: 'auto',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	halfWidth: {
		width: '48%',
	},
})

class RegisterEnroll extends React.Component {
	state = {
		firstName: '',
		lastName: '',
		email: '',
		email2: '',
		password: '',
		password2: '',
		phone: '',
		city: '',
		whereMet: '',
		firstNameValidate: false,
		lastNameValidate: false,
		emailValidate: false,
		passwordValidate: false,
		phoneValidate: false,
		emailHelper: '',
		passwordHelper: '',
		phoneHelper: '',
		firstNameHelper: '',
		lastNameHelper: '',
		modalOpen: false,
		errorMessage: '',
		linkObject: {},
		redirect: false,
		modalTitle: '',
		cep: '',
		street: '',
		number: '',
		complement: '',
		neighborhood: '',
		state: '',
		cepValidate: false,
		streetValidate: false,
		numberValidate: false,
		neighborhoodValidate: false,
		cityValidate: false,
		stateValidate: false,
		cepHelperText: '',
		streetHelperText: '',
		numberHelperText: '',
		neighborhoodHelperText: '',
		cityHelperText: '',
		stateHelperText: '',
	}

	componentDidMount = () => {
		if (!(this.props.location.search.length === 0)) {
			return this.setState({
				linkObject: queryString.parse(this.props.location.search),
			})
		}
		window.location.href = 'https://plataforma.neuropsicologia.online'
	}

	componentDidUpdate() {
		if (this.state.redirect) {
			window.location.href =
				'https://plataforma.neuropsicologia.online/users/sign_in'
		}
	}

	_handleFirstName = event => {
		this.setState({ firstName: event.target.value })
	}

	_handleLastName = event => {
		this.setState({ lastName: event.target.value })
	}

	_handleEmailOne = event => {
		this.setState({ email: event.target.value })
	}

	_handleEmailTwo = event => {
		this.setState({ email2: event.target.value })
	}

	_handlePassword = event => {
		this.setState({ password: event.target.value })
	}

	_handlePasswordTwo = event => {
		this.setState({ password2: event.target.value })
	}

	_handlePhone = event => {
		this.setState({ phone: phoneMask(event.target.value) })
	}

	_handleCity = event => {
		this.setState({ city: event.target.value })
	}

	_handleWhereMet = event => {
		this.setState({ whereMet: event.target.value })
	}
	_handleAddress = (field, event) => {
		if (field === 'cep' && event.target.value.match(/^[0-9]*$/)) {
			return this.setState({ [field]: event.target.value })
		}
		if (field !== 'cep') {
			return this.setState({ [field]: event.target.value })
		}
		return
	}

	checkEmail = () => {
		if (this.state.email === this.state.email2)
			this.setState({ emailValidate: false, emailHelper: '' })
		else
			this.setState({
				emailValidate: true,
				emailHelper: 'Os dois e-mails digitados são diferentes',
			})
	}

	checkPassword = () => {
		if (this.state.password === this.state.password2)
			this.setState({ passwordValidate: false, passwordHelper: '' })
		else
			this.setState({
				passwordValidate: true,
				passwordHelper: 'As duas senhas não conferem',
			})
	}

	checkPhone = () => {
		if (this.state.phone.length < 14 || this.state.phone.length > 15) {
			this.setState({ phoneValidate: true, phoneHelper: 'Telefone inválido' })
		} else this.setState({ phoneValidate: false, phoneHelper: '' })
	}

	checkFirstName = () => {
		if (this.state.firstName !== '')
			this.setState({ firstNameValidate: false, firstNameHelper: '' })
	}

	checkLastName = () => {
		if (this.state.lastName !== '')
			this.setState({ lastNameValidate: false, lastNameHelper: '' })
	}

	checkValidations = () => {
		if (
			this.state.firstNameValidate ||
			this.state.lastNameValidate ||
			this.state.emailValidate ||
			this.state.passwordValidate ||
			this.state.phoneValidate ||
			this.state.cepValidate ||
			this.state.streetValidate ||
			this.state.numberValidate ||
			this.state.neighborhoodValidate ||
			this.state.cityValidate ||
			this.state.stateValidate
		)
			return true

		return false
	}

	_handleModalClose = () => {
		this.setState({ modalOpen: false })
	}

	getAddress = () => {
		axios
			.get(`https://viacep.com.br/ws/${this.state.cep}/json/`)
			.then(response => {
				if (response.status === 200) {
					return this.setState({
						street: response.data.logradouro,
						neighborhood: response.data.bairro,
						city: response.data.localidade,
						state: response.data.uf,
					})
				}
				this.setState({
					cepValidate: true,
					cepHelperText: 'CEP inválido',
				})
			})
	}

	submitForm = () => {
		if (this.state.firstName === '') {
			this.setState({
				firstNameValidate: true,
				firstNameHelper: 'Campo está em branco',
			})
		}
		if (this.state.lastName === '') {
			this.setState({
				lastNameValidate: true,
				lastNameHelper: 'Campo está em branco',
			})
		}
		if (this.state.email === '') {
			this.setState({
				emailValidate: true,
				emailHelper: 'Campo está em branco',
			})
		}
		if (this.state.email2 === '') {
			this.setState({
				emailValidate: true,
				emailHelper: 'Campo está em branco',
			})
		}
		if (this.state.phone === '') {
			this.setState({
				phoneValidate: true,
				phoneHelper: 'Campo está em branco',
			})
		}
		if (this.state.password === '') {
			this.setState({
				passwordValidate: true,
				passwordHelper: 'Campo está em branco',
			})
		}
		if (this.state.password2 === '') {
			this.setState({
				passwordValidate: true,
				passwordHelper: 'Campo está em branco',
			})
		}
		if (this.state.cep === '') {
			this.setState({
				cepValidate: true,
				cepHelperText: 'Campo está em branco',
			})
		}
		if (this.state.cep.length < 8) {
			this.setState({
				cepValidate: true,
				cepHelperText: 'CEP menor que 8 dígitos',
			})
		}
		if (this.state.street === '') {
			this.setState({
				streetValidate: true,
				streetHelperText: 'Campo está em branco',
			})
		}
		if (this.state.number === '') {
			this.setState({
				numberValidate: true,
				numberHelperText: 'Campo está em branco',
			})
		}
		if (this.state.neighborhood === '') {
			this.setState({
				neighborhoodValidate: true,
				neighborhoodHelperText: 'Campo está em branco',
			})
		}
		if (this.state.city === '') {
			this.setState({
				cityValidate: true,
				cityHelperText: 'Campo está em branco',
			})
		}
		if (this.state.state === '') {
			this.setState({
				stateValidate: true,
				stateHelperText: 'Campo está em branco',
			})
		}
		if (this.checkValidations()) return
		else {
			let body = {
				first_name: this.state.firstName,
				last_name: this.state.lastName,
				email: this.state.email,
				password: this.state.password,
				where_met: this.state.whereMet,
				phone: this.state.phone,
				cpf: this.state.cpf,
				cep: this.state.cep,
				street: this.state.street,
				number: this.state.number,
				complement: this.state.complement,
				neighborhood: this.state.neighborhood,
				city: this.state.city,
				state: this.state.state,
			}
			axios
				.post(`${API_URL}/user/registerUser`, body, {
					headers: {
						authorization: process.env.REACT_APP_APIKEY,
					},
				})
				.then(response => {
					if (response.status === 200) {
						const data = {
							course_id: courseHash[this.state.linkObject.p]['code'],
							customer: response.data.data.id,
							customerName: `${this.state.firstName} ${this.state.lastName}`,
							customerEmail: this.state.email,
						}
						axios
							.post(`${API_URL}/courses/enroll`, data, {
								headers: {
									authorization: process.env.REACT_APP_APIKEY,
								},
							})
							.then(response => {
								if (response.status === 200) {
									this.setState({
										modalTitle: 'SUCESSO',
										modalOpen: true,
										errorMessage:
											'Seu cadastro foi realizado e seu curso liberado!! Assim que fechar essa janela você será redirecionado(a) para a plataforma. ',
										redirect: true,
									})
								}
							})
					}
				})
				.catch(error => {
					this.setState({
						modalTitle: 'ERRO',
						modalOpen: true,
						errorMessage:
							'Não foi possível cadastrar o usuário. Tente digitar este e-mail na tela de login para conferir se já não está cadastrado na plataforma. ',
					})
				})
		}
	}

	render() {
		const { classes } = this.props
		return (
			<Grid container component='main' className={classes.root}>
				<CssBaseline />
				<Grid item xs={false} sm={4} md={7} className={classes.image} />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<div className={classes.paper}>
						<div className={classes.avatar}>
							<img src={NeuroLogo} alt='' className={classes.logo} />
						</div>
						<Typography component='h1' variant='h5'>
							Preencha abaixo as suas informações de cadastro
						</Typography>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.firstName}
							onChange={this._handleFirstName}
							onBlur={this.checkFirstName}
							helperText={this.state.firstNameHelper}
							error={this.state.firstNameValidate}
							id='name'
							label='Primeiro nome'
							name='name'
							autoComplete='name'
							autoFocus
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.lastName}
							onChange={this._handleLastName}
							onBlur={this.checkLastName}
							helperText={this.state.lastNameHelper}
							error={this.state.lastNameValidate}
							id='lastname'
							label='Sobrenome'
							name='lastname'
							autoComplete='lastname'
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.email}
							onChange={this._handleEmailOne}
							onBlur={this.checkEmail}
							helperText={this.state.emailHelper}
							error={this.state.emailValidate}
							id='email1'
							label='Endereço de e-mail'
							name='email'
							autoComplete='email'
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.email2}
							onChange={this._handleEmailTwo}
							onBlur={this.checkEmail}
							helperText={this.state.emailHelper}
							id='email2'
							label='Repita o seu e-mail'
							name='email'
							autoComplete='email'
							error={this.state.emailValidate}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.password}
							onChange={this._handlePassword}
							onBlur={this.checkPassword}
							helperText={this.state.passwordHelper}
							error={this.state.passwordValidate}
							id='password'
							label='Sua senha'
							type='password'
							name='password'
							autoComplete='current-password'
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.password2}
							onChange={this._handlePasswordTwo}
							onBlur={this.checkPassword}
							helperText={this.state.passwordHelper}
							error={this.state.passwordValidate}
							id='password2'
							label='Digite novamente a sua senha'
							name='password2'
							type='password'
							autoComplete='current-password'
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.phone}
							onChange={this._handlePhone}
							onBlur={this.checkPhone}
							placeholder='Digite somente números com DDD de 2 dígitos'
							id='phone'
							label='Telefone'
							name='phone'
							autoComplete='phone'
							error={this.state.phoneValidate}
							helperText={this.state.phoneHelper}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							fullWidth
							value={this.state.whereMet}
							onChange={this._handleWhereMet}
							id='wheremet'
							label='Por onde nos conheceu?'
							name='wheremet'
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.cep}
							onChange={e => this._handleAddress('cep', e)}
							onBlur={this.getAddress}
							id='cep'
							label='CEP (Somente números)'
							name='cep'
							error={this.state.cepValidate}
							helperText={this.state.cepHelperText}
							inputProps={{ maxLength: 8 }}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.street}
							onChange={e => this._handleAddress('street', e)}
							id='street'
							label='Logradouro'
							name='street'
							helperText={this.state.streetHelperText}
							error={this.state.streetValidate}
						/>
						<div className={classes.addressDiv}>
							<TextField
								variant='outlined'
								margin='normal'
								required
								value={this.state.number}
								onChange={e => this._handleAddress('number', e)}
								id='number'
								label='Número'
								name='number'
								className={classes.halfWidth}
								helperText={this.state.numberHelperText}
								error={this.state.numberValidate}
							/>
							<TextField
								variant='outlined'
								margin='normal'
								value={this.state.complement}
								onChange={e => this._handleAddress('complement', e)}
								id='complement'
								label='Complemento'
								name='complement'
								className={classes.halfWidth}
							/>
						</div>
						<TextField
							variant='outlined'
							margin='normal'
							fullWidth
							required
							value={this.state.neighborhood}
							onChange={e => this._handleAddress('neighborhood', e)}
							id='neighborhood'
							label='Bairro'
							name='neighborhood'
							helperText={this.state.neighborhoodHelperText}
							error={this.state.neighborhoodValidate}
						/>
						<div className={classes.addressDiv}>
							<TextField
								variant='outlined'
								margin='normal'
								required
								value={this.state.city}
								onChange={e => this._handleAddress('city', e)}
								id='addressCity'
								label='Cidade'
								name='city'
								className={classes.halfWidth}
								helperText={this.state.cityHelperText}
								error={this.state.cityValidate}
							/>
							<TextField
								variant='outlined'
								margin='normal'
								required
								value={this.state.state}
								onChange={e => this._handleAddress('state', e)}
								id='state'
								label='Estado'
								name='state'
								className={classes.halfWidth}
								helperText={this.state.stateHelperText}
								error={this.state.stateValidate}
							/>
						</div>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							className={classes.submit}
							onClick={this.submitForm}
						>
							CADASTRAR E LIBERAR CURSO
						</Button>
					</div>
				</Grid>
				<Dialog open={this.state.modalOpen} onClose={this._handleModalClose}>
					<DialogTitle>{this.state.modalTitle}</DialogTitle>
					<DialogContent>
						<DialogContentText>{this.state.errorMessage}</DialogContentText>
					</DialogContent>
				</Dialog>
			</Grid>
		)
	}
}

RegisterEnroll.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegisterEnroll)
