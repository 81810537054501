import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import pagarme from 'pagarme'
import Background from '../Login/img2.jpg'
import NeuroLogo from '../Login/logo-light.PNG'
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  CssBaseline,
  Typography,
  Button,
  Paper,
  Link,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CreditCardForm from './CreditCardForm'
import { API_URL } from '../util/constants'
import { usePayment } from '../../store'
import cursos from '../util/cursos.json'

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(' + Background + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '220px',
    height: '100px',
  },
  logo: {
    width: '220px',
    height: 'auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  couponDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
    marginBottom: '4%',
  },
  couponInput: {
    width: '70%',
  },
  couponButton: {
    width: '20%',
    height: '42px',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
  select: {
    width: '100%',
  },
  discountText: {
    fontSize: 16,
  },
  amountDiscounted: {
    color: '#f43542',
  },
})

const Payment = ({ classes, history }) => {
  const [modalData, setModalData] = useState({
    open: false,
    title: '',
    message: '',
    buttonText: 'PAGAR',
    card_confirmed: false,
  })
  const [couponData, setCouponData] = useState({
    error: false,
    errorMessage: '',
  })
  const [boleto, setBoleto] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { $payment, setField, getInstallmentOptions } = usePayment()
  const {
    coupon,
    transaction,
    product_name,
    amount,
    method,
    installmentsOptions,
    couponConfirmed,
    product_id,
    id,
    first_name,
    last_name,
    email,
    card_number,
    card_name,
    card_expiry_date,
    card_installments,
    card_cvc,
    cpf,
    phone,
    cep,
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
    issub,
    partnerCode,
  } = $payment
  const [fullValue] = useState(amount)
  useEffect(() => {
    if (issub && transaction === 'checkout' && Number(product_id) !== 22999) {
      setField('amount', amount * 0.8)
      setField('installmentsOptions', [])
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!modalData.open && boleto.length) {
      window.location.replace(boleto)
    } else if (!modalData.open && modalData.card_confirmed) {
      history.push({
        pathname: '/pagamentos/finished',
        query: { address: false },
      })
    }
    // eslint-disable-next-line
  }, [modalData.open, boleto, modalData.card_confirmed])

  useEffect(() => {
    const getInstallments = async () => {
      const options = await getInstallmentOptions(amount, product_id)
      setField('installmentsOptions', options)
    }
    if (
      transaction === 'checkout' &&
      method === 'credit-card' &&
      !installmentsOptions.length
    ) {
      getInstallments()
    }
    // eslint-disable-next-line
  }, [amount])

  const validateCoupon = () => {
    if (coupon === '' || couponConfirmed) return

    axios
      .post(
        `${API_URL}/coupons/checkCoupon`,
        {
          coupon_code: coupon,
          course_code: product_name,
        },
        {
          headers: {
            authorization: process.env.REACT_APP_APIKEY,
          },
        }
      )
      .then(response => {
        if (response.data.type === 'percentage') {
          const new_amount = issub
            ? fullValue - fullValue * (response.data.discount / 100 + 0.2)
            : amount - amount * (response.data.discount / 100)
          setField('installmentsOptions', [])
          setAmount(new_amount)
          setCouponData({
            error: false,
            errorMessage: '',
          })
          setModalData(prev => ({
            ...prev,
            buttonText: new_amount === 0 ? 'LIBERAR CURSO' : 'PAGAR',
          }))
          setField('couponConfirmed', true)
          return
        }
        const new_amount = amount - response.data.discount
        setField('installmentsOptions', [])
        setAmount(new_amount)
        setCouponData({
          error: false,
          errorMessage: '',
        })
        setModalData(prev => ({
          ...prev,
          buttonText: new_amount === 0 ? 'LIBERAR CURSO' : 'PAGAR',
        }))
        setField('couponConfirmed', true)
        return
      })
      .catch(error => {
        setCouponData({
          error: true,
          errorMessage: 'Cupom inválido',
        })
      })
  }

  const renderCoupon = () => {
    return (
      <div className={classes.couponDiv}>
        <TextField
          variant='outlined'
          margin='normal'
          value={coupon}
          onChange={e => setField('coupon', e.target.value.toUpperCase())}
          id='coupon'
          label='Tem um cupom? Digite o código aqui'
          name='coupon'
          autoFocus
          className={classes.couponInput}
          error={couponData.error}
          helperText={couponData.errorMessage}
        />
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={() => validateCoupon()}
          className={classes.couponButton}
          disabled={couponConfirmed}
        >
          {couponConfirmed ? 'Aplicado!' : 'Validar'}
        </Button>
      </div>
    )
  }

  const setAmount = newAmount => {
    setField('amount', newAmount)
  }

  const submitForm = () => {
    setLoading(true)
    if (modalData.buttonText === 'LIBERAR CURSO') {
      axios
        .post(
          `${API_URL}/courses/enroll`,
          {
            course_id: product_id,
            customer: id.toString(),
            customerName: `${first_name} ${last_name}`,
            customerEmail: email,
          },
          {
            headers: {
              authorization: process.env.REACT_APP_APIKEY,
            },
          }
        )
        .then(response => {
          if (response.status === 200) {
            setLoading(false)
            setModalData(prev => ({
              ...prev,
              open: true,
              message:
                'Muito bem! O seu curso foi liberado. Você receberá uma confirmação em seu e-mail!',
              title: 'SUCESSO',
              card_confirmed: true,
            }))
          }
        })
        .catch(error => {
          setLoading(false)
          setModalData(prev => ({
            ...prev,
            open: true,
            message:
              'Ocorreu um erro ao processar a transação. Confira os dados do seu cartão e tente novamente. Caso não consiga, entre em contato com contato@neuropsicologia.online para obter ajuda. ',
            title: 'ERRO',
          }))
        })
    }
    const full_name = `${first_name} ${last_name}`

    if (method === 'credit-card') {
      let strings = card_expiry_date.split('/')
      let expiry = strings[0].concat(strings[1])
      pagarme.client
        .connect({ encryption_key: process.env.REACT_APP_PAGARME_ENCRYPT })
        .then(client =>
          client.security.encrypt({
            card_number: card_number,
            card_holder_name: card_name,
            card_expiration_date: expiry,
            card_cvv: card_cvc,
          })
        )
        .then(card_hash => {
          if (transaction === 'checkout') {
            const body = {
              payment_method: 'credit_card',
              card_hash: card_hash,
              name: full_name,
              email: email,
              amount: amount,
              id: id.toString(),
              cpf: cpf,
              cellphone: phone,
              product_id: product_id,
              product_name: product_name,
              installments: card_installments,
              cep: cep,
              street: street,
              number: number,
              complement: complement,
              neighborhood: neighborhood,
              city: city,
              state: state,
              partner_code: partnerCode,
            }
            axios
              .post(`${API_URL}/payment/checkoutTransaction`, body, {
                headers: {
                  authorization: process.env.REACT_APP_APIKEY,
                },
              })
              .then(response => {
                setLoading(false)
                if (response.status === 200) {
                  setModalData(prev => ({
                    ...prev,
                    open: true,
                    message:
                      'Muito bem! O seu pagamento está sendo processado e você receberá uma confirmação em seu e-mail!',
                    title: 'SUCESSO',
                    card_confirmed: true,
                  }))
                }
              })
              .catch(error => {
                setLoading(false)
                setModalData(prev => ({
                  ...prev,
                  open: true,
                  message:
                    'Ocorreu um erro ao processar a transação. Confira os dados do seu cartão e tente novamente. Caso não consiga, entre em contato com contato@neuropsicologia.online para obter ajuda. ',
                  title: 'ERRO',
                }))
              })
          } else if (transaction === 'subscription') {
            const body = {
              payment_method: 'credit_card',
              card_hash: card_hash,
              name: full_name,
              email: email,
              id: id.toString(),
              cpf: cpf,
              cellphone: phone,
              cep: cep,
              street: street,
              number: number,
              complement: complement,
              neighborhood: neighborhood,
              city: city,
              state: state,
            }
            axios
              .post(`${API_URL}/payment/subscriptionTransaction`, body, {
                headers: {
                  authorization: process.env.REACT_APP_APIKEY,
                },
              })
              .then(response => {
                setLoading(false)
                if (response.status === 200) {
                  setModalData(prev => ({
                    ...prev,
                    open: true,
                    message:
                      'Muito bem! O seu pagamento está sendo processado e você receberá uma confirmação em seu e-mail!',
                    title: 'SUCESSO',
                    card_confirmed: true,
                  }))
                }
              })
              .catch(error => {
                setLoading(false)
                setModalData(prev => ({
                  ...prev,
                  open: true,
                  message:
                    'Ocorreu um erro ao processar a transação. Confira os dados do seu cartão e tente novamente. Caso não consiga, entre em contato com contato@neuropsicologia.online para obter ajuda. ',
                  title: 'ERRO',
                }))
              })
          }
        })
        .catch(error => {
          setLoading(false)
          setModalData(prev => ({
            ...prev,
            open: true,
            message:
              'Os dados do seu cartão são inválidos. Confira e tente novamente.',
            title: 'ERRO',
          }))
        })
    } else if (method === 'boleto') {
      if (transaction === 'checkout') {
        const body = {
          payment_method: 'boleto',
          name: full_name,
          email: email,
          amount: amount,
          id: id.toString(),
          cpf: cpf,
          cellphone: phone,
          product_id: product_id,
          product_name: product_name,
          installments: 1,
          cep: cep,
          street: street,
          number: number,
          complement: complement,
          neighborhood: neighborhood,
          city: city,
          state: state,
          partner_code: partnerCode,
        }
        axios
          .post(`${API_URL}/payment/checkoutTransaction`, body, {
            headers: {
              authorization: process.env.REACT_APP_APIKEY,
            },
          })
          .then(response => {
            setLoading(false)
            if (response.status === 200) {
              setModalData(prev => ({
                ...prev,
                open: true,
                message:
                  'Muito bem! O seu boleto foi gerado, e você será redirecionado(a) a ele assim que você clicar fora desta janela de texto! ',
                title: 'SUCESSO',
              }))
              setBoleto(response.data.boleto)
            }
          })
          .catch(error => {
            setLoading(false)
            setModalData(prev => ({
              ...prev,
              open: true,
              message:
                'Ocorreu um erro ao processar a transação. Entre em contato com contato@neuropsicologia.online para obter ajuda. ',
              title: 'ERRO',
            }))
          })
      } else if (transaction === 'subscription') {
        const body = {
          payment_method: 'boleto',
          name: full_name,
          email: email,
          id: id.toString(),
          cpf: cpf,
          cellphone: phone,
          cep: cep,
          street: street,
          number: number,
          complement: complement,
          neighborhood: neighborhood,
          city: city,
          state: state,
        }

        axios
          .post(`${API_URL}/payment/subscriptionTransaction`, body, {
            headers: {
              authorization: process.env.REACT_APP_APIKEY,
            },
          })
          .then(response => {
            setLoading(false)
            if (response.status === 200) {
              setModalData(prev => ({
                ...prev,
                open: true,
                message:
                  'Muito bem! O seu boleto foi gerado, e você será redirecionado(a) a ele assim que você clicar fora desta janela de texto! ',
                title: 'SUCESSO',
              }))
              setBoleto(response.data.boleto)
            }
          })
          .catch(error => {
            setLoading(false)
            setModalData(prev => ({
              ...prev,
              open: true,
              message:
                'Ocorreu um erro ao processar a transação. Entre em contato com contato@neuropsicologia.online para obter ajuda. ',
              title: 'ERRO',
            }))
          })
      }
    }
  }

  const openTerms = () => {
    const newWindow = window.open(
      'https://plataforma.neuropsicologia.online/pages/termos-de-uso-e-politicas-de-cancelamento',
      '_blank'
    )
    newWindow.focus()
  }

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.avatar}>
            <img src={NeuroLogo} alt='' className={classes.logo} />
          </div>
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '18px',
              marginBottom: '10px',
            }}
          >
            {transaction === 'checkout'
              ? cursos[product_name]
              : 'Raciocínio Clínico em Neuropsicologia - Assinatura'}
          </Typography>
          {issub &&
          transaction === 'checkout' &&
          Number(product_id) !== 22999 ? (
            <p className={classes.discountText}>
              Ei, espera aí! Vimos aqui que você é aluno(a) do nosso curso de
              Raciocínio Clínico em Neuropsicologia. Sabia que você tem 20% de
              desconto em todos os nossos cursos específicos só pelo fato de
              estar com sua assinatura ativa? Aplicamos já o seu desconto
              automaticamente aqui. Agora este curso está saindo por{' '}
              <strong className={classes.amountDiscounted}>{`R$${(
                amount / 100
              ).toFixed(2)}`}</strong>
              &nbsp;e você economizou{' '}
              <strong className={classes.amountDiscounted}>{`R$${(
                (fullValue / 100) *
                0.2
              ).toFixed(2)}`}</strong>
              !! Bons estudos! :)`}
            </p>
          ) : (
            <Typography component='h5' variant='h5'>
              Valor:{' '}
              {transaction === 'checkout' ? `R$${amount / 100}` : 'R$189,00'}
            </Typography>
          )}
          <Typography component='h1' variant='h5'>
            Escolha a opção de pagamento.
          </Typography>
          <FormControl component='fieldset' className={classes.formControl}>
            <FormLabel component='legend'>Forma de Pagamento</FormLabel>
            <RadioGroup
              name='payment1'
              className={classes.group}
              value={method}
              onChange={e => setField('method', e.target.value)}
            >
              <FormControlLabel
                value='credit-card'
                control={<Radio color='primary' />}
                label='Cartão de Crédito'
              />
              <FormControlLabel
                value='boleto'
                control={<Radio color='primary' />}
                label='Boleto Bancário'
              />
            </RadioGroup>
          </FormControl>
          {method === 'credit-card' ? (
            <>
              <div className='form'>
                <CreditCardForm selectClassName={classes.select} />
              </div>
              {transaction === 'checkout' ? renderCoupon() : ''}
            </>
          ) : (
            <>
              {transaction === 'checkout' ? renderCoupon() : ''}
              <Typography component='h3'>
                Para receber o boleto em seu e-mail, clique em PAGAR
              </Typography>
            </>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            onClick={() => submitForm()}
            className={classes.submit}
            disabled={isLoading}
          >
            {modalData.buttonText}
          </Button>
          <Typography>
            Ao efetuar o pagamento você estará concordando com nossos{' '}
            <Link href='#' onClick={openTerms}>
              termos de uso
            </Link>
          </Typography>
        </div>
      </Grid>
      <Dialog
        open={modalData.open}
        onClose={() => setModalData(prev => ({ ...prev, open: false }))}
      >
        <DialogTitle>{modalData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalData.message}</DialogContentText>
          <Button
            type='button'
            fullWidth
            variant='contained'
            color='primary'
            onClick={() => setModalData(prev => ({ ...prev, open: false }))}
            className={classes.submit}
          >
            Ok
          </Button>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

Payment.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Payment)
