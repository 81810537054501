import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Background from './img2.jpg'
import NeuroLogo from './logo-light.PNG'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from '@material-ui/core'
import { API_URL } from '../util/constants'

const styles = theme => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(' + Background + ')',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '220px',
		height: '100px',
	},
	logo: {
		width: '220px',
		height: 'auto',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
})

class Login extends React.Component {
	state = {
		email: '',
		validateEmail: false,
		helperEmail: '',
		modalOpen: false,
		errorMessage: '',
	}

	_handleEmail = event => {
		this.setState({ email: event.target.value })
	}

	submitForm = () => {
		if (this.state.email === '') {
			this.setState({
				validateEmail: true,
				helperEmail: 'Campo está em branco',
			})
			return
		} else {
			this.setState({ validateEmail: false, helperEmail: '' })
			axios
				.get(`${API_URL}/user/` + this.state.email, {
					headers: {
						authorization: process.env.REACT_APP_APIKEY,
					},
				})
				.then(response => {
					const data = {
						first_name: response.data.data[0].first_name,
						last_name: response.data.data[0].last_name,
						email: this.state.email,
						id: response.data.data[0].id,
						cpf: response.data.data[0].custom_profile_fields[3].value,
						phone: response.data.data[0].custom_profile_fields[2].value,
						address: true,
					}
					if (response.status === 200) {
						if (
							data.cpf === null ||
							data.cpf.length === 0 ||
							data.phone === null ||
							data.phone.length === 0 ||
							response.data.noAddress
						) {
							this.props.history.push({
								pathname: '/pagamentos/completeregistration',
								query: data,
							})
						} else {
							this.props.history.push({
								pathname: '/pagamentos/finished',
								query: data,
							})
						}
					}
				})
				.catch(error => {
					console.log(error)
					this.setState({
						modalOpen: true,
						errorMessage: 'E-mail inválido',
					})
				})
		}
	}

	_handleModalClose = () => {
		this.setState({ modalOpen: false })
	}

	render() {
		const { classes } = this.props
		return (
			<Grid container component='main' className={classes.root}>
				<CssBaseline />
				<Grid item xs={false} sm={4} md={7} className={classes.image} />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<div className={classes.paper}>
						<div className={classes.avatar}>
							<img src={NeuroLogo} alt='' className={classes.logo} />
						</div>
						<Typography component='h1' variant='h5'>
							Digite seu e-mail abaixo para verificar se o cadastro está
							completo.
						</Typography>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							value={this.state.email}
							onChange={this._handleEmail}
							id='email'
							label='Endereço de e-mail'
							name='email'
							autoComplete='email'
							autoFocus
							error={this.state.validateEmail}
							helperText={this.state.helperEmail}
						/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							onClick={this.submitForm}
							className={classes.submit}
						>
							CONTINUAR
						</Button>
					</div>
				</Grid>
				<Dialog open={this.state.modalOpen} onClose={this._handleModalClose}>
					<DialogTitle>Erro</DialogTitle>
					<DialogContent>
						<DialogContentText>{this.state.errorMessage}</DialogContentText>
					</DialogContent>
				</Dialog>
			</Grid>
		)
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Login)
