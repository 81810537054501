import axios from 'axios'
import base64 from 'base-64'
import { API_URL } from '../../components/util/constants'

export default setPayment => {
  const setField = (field, value) => {
    setPayment(prev => ({ ...prev, [field]: value }))
  }

  const clearState = () => {
    setPayment({
      email: '',
      email2: '',
      validateEmail: false,
      helperEmail: '',
      errorMessage: '',
      transaction: '',
      product_id: 0,
      product_name: '',
      amount: '',
      first_name: '',
      last_name: '',
      password: '',
      password2: '',
      where_met: '',
      id: 0,
      cpf: '',
      phone: '',
      cep: '',
      street: '',
      number: 0,
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      coupon: '',
      method: 'credit-card',
      card_number: '',
      card_cvc: '',
      card_name: '',
      card_expiry_date: '',
      card_installments: 1,
      installmentsOptions: [],
      couponConfirmed: false,
      issub: false,
    })
  }

  const setUserData = data => {
    const {
      first_name,
      last_name,
      id,
      cpf,
      phone,
      cep,
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      issub,
    } = data

    setPayment(prev => ({
      ...prev,
      first_name,
      last_name,
      id,
      cpf,
      phone,
      cep,
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      issub,
    }))
  }

  const setSearchData = async data => {
    const { transaction } = data
    if (transaction === 'subscription') {
      setField('transaction', transaction)
      return
    }

    const { amount, discount, product_id, product_name, partner_code } = data
    const partnerCode = partner_code === undefined ? 0 : partner_code
    const amountValue = parseInt(base64.decode(amount))
    const discountValue = parseInt(base64.decode(discount))
    const amountWithDiscount = amountValue - (discountValue / 100) * amountValue
    const installmentsOptions = await getInstallmentOptions(
      amountWithDiscount,
      product_id
    )
    const couponConfirmed = discountValue > 0
    setPayment(prev => ({
      ...prev,
      transaction,
      amount: amountWithDiscount,
      product_id,
      product_name,
      installmentsOptions,
      couponConfirmed,
      partnerCode,
    }))
  }

  const setAddressViaCep = data => {
    const { street, neighborhood, city, state } = data
    setPayment(prev => ({
      ...prev,
      street,
      neighborhood,
      city,
      state,
    }))
  }

  const getInstallmentOptions = (amount, product_id) => {
    return axios
      .get(`${API_URL}/payment/installments/${amount}/${product_id}`, {
        headers: {
          authorization: process.env.REACT_APP_APIKEY,
        },
      })
      .then(installments => {
        return installments.data
      })
      .catch(err => console.log(err))
  }

  return {
    setField,
    setUserData,
    setSearchData,
    setAddressViaCep,
    getInstallmentOptions,
    clearState,
  }
}
