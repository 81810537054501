import React from 'react'
import PropTypes from 'prop-types'

import { PaymentProvider } from '../store'

const StateProvider = ({ children }) => (
	<PaymentProvider>{children}</PaymentProvider>
)

StateProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export default StateProvider
