import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
} from '@material-ui/core'
import { cpfMask } from '../util/CPFMask'
import { phoneMask } from '../util/PhoneMask'
import Background from '../Login/img2.jpg'
import NeuroLogo from '../Login/logo-light.PNG'
import { usePayment } from '../../store'
import axios from 'axios'
import { API_URL } from '../util/constants'

const styles = theme => ({
  root: {
    height: '100vh',
  },
  addressDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  image: {
    backgroundImage: 'url(' + Background + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '220px',
    height: '100px',
  },
  logo: {
    width: '220px',
    height: 'auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  halfWidth: {
    width: '48%',
  },
})

const CompleteRegistration = ({ classes, history, location }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [validators, setValidators] = useState({
    password: { msg: '', error: false },
    cpf: { msg: '', error: false },
    phone: { msg: '', error: false },
    cep: { msg: '', error: false },
    street: { msg: '', error: false },
    number: { msg: '', error: false },
    neighborhood: { msg: '', error: false },
    city: { msg: '', error: false },
    state: { msg: '', error: false },
  })
  const { $payment, setField, setAddressViaCep } = usePayment()
  const {
    first_name,
    last_name,
    email,
    id,
    password,
    password2,
    cpf,
    phone,
    cep,
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
    errorMessage,
  } = $payment

  const checkPassword = () => {
    if (password === password2) {
      setValidators(prev => ({ ...prev, password: { error: false, msg: '' } }))
      return
    }
    setValidators(prev => ({
      ...prev,
      password: { error: true, msg: 'As duas senhas não conferem' },
    }))
  }

  const checkCPF = () => {
    if (cpf.length != 14) {
      setValidators(prev => ({
        ...prev,
        cpf: { error: true, msg: 'CPF inválido' },
      }))
      return
    }
    let sum = 0,
      iterator = 10,
      validator
    let cpfCp = cpf
    cpfCp = cpfCp.split('.').join('')
    cpfCp = cpfCp.split('-').join('')
    cpfCp.split('').forEach(number => {
      sum += iterator * number
      iterator--
    })
    sum -= parseInt(cpfCp[9])
    validator = (sum * 10) % 11
    validator = validator === 10 ? 0 : validator
    validator = validator === 11 ? 0 : validator
    if (cpfCp[9] != validator) {
      setValidators(prev => ({
        ...prev,
        cpf: { error: true, msg: 'CPF inválido' },
      }))
      return
    }

    iterator = 11
    sum = 0
    cpfCp.split('').forEach(number => {
      sum += iterator * number
      iterator--
    })
    sum -= parseInt(cpfCp[10])
    validator = (sum * 10) % 11
    validator = validator === 10 ? 0 : validator
    validator = validator === 11 ? 0 : validator
    if (cpfCp[10] != validator) {
      setValidators(prev => ({
        ...prev,
        cpf: { error: true, msg: 'CPF inválido' },
      }))
      return
    }

    setValidators(prev => ({
      ...prev,
      cpf: { error: false, msg: '' },
    }))
  }

  const checkPhone = () => {
    if (phone.length < 14 || phone.length > 15) {
      setValidators(prev => ({
        ...prev,
        phone: { error: true, msg: 'Telefone inválido' },
      }))
      return
    }
    setValidators(prev => ({
      ...prev,
      phone: { error: false, msg: '' },
    }))
  }

  const getAddress = () => {
    axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
      if (response.status === 200) {
        const data = {
          street: response.data.logradouro,
          neighborhood: response.data.bairro,
          city: response.data.localidade,
          state: response.data.uf,
        }
        return setAddressViaCep(data)
      }
      setValidators(prev => ({
        ...prev,
        cep: { error: true, msg: 'CEP inválido' },
      }))
    })
  }

  const checkValidations = () => {
    if (cpf === '') {
      setValidators(prev => ({
        ...prev,
        cpf: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (phone === '') {
      setValidators(prev => ({
        ...prev,
        phone: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (password === '') {
      setValidators(prev => ({
        ...prev,
        password: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (password2 === '') {
      setValidators(prev => ({
        ...prev,
        password: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (cep === '') {
      setValidators(prev => ({
        ...prev,
        cep: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (cep.length < 8) {
      setValidators(prev => ({
        ...prev,
        cep: { error: true, msg: 'CEP menor que 8 digitos' },
      }))
    }
    if (street === '') {
      setValidators(prev => ({
        ...prev,
        street: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (number === '') {
      setValidators(prev => ({
        ...prev,
        number: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (neighborhood === '') {
      setValidators(prev => ({
        ...prev,
        neighborhood: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (city === '') {
      setValidators(prev => ({
        ...prev,
        city: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (state === '') {
      setValidators(prev => ({
        ...prev,
        state: { error: true, msg: 'Campo está em branco' },
      }))
    }
    if (
      validators.password.error ||
      validators.cpf.error ||
      validators.phone.error ||
      validators.cep.error ||
      validators.street.error ||
      validators.number.error ||
      validators.neighborhood.error ||
      validators.city.error ||
      validators.state.error
    ) {
      return true
    }
    return false
  }

  const submitForm = event => {
    event.preventDefault()
    if (checkValidations()) return
    const payload = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      id: id,
      cpf: cpf,
      phone: phone,
      password: password,
      cep: cep,
      street: street,
      number: number,
      complement: complement,
      neighborhood: neighborhood,
      city: city,
      state: state,
    }
    axios
      .post(`${API_URL}/user/updateUser`, payload, {
        headers: {
          authorization: process.env.REACT_APP_APIKEY,
        },
      })
      .then(response => {
        if (response.status === 204) {
          const path = location.query
            ? '/pagamentos/finished'
            : '/pagamentos/payment'
          const address = location.query ? true : false
          history.push({
            pathname: path,
            query: { address },
          })
        }
      })
      .catch(error => {
        console.log(error)
        setField('errorMessage', 'Não foi possivel atualizar o cadastro.')
        setModalOpen(true)
      })
  }

  const setCEP = number => {
    if (number.match(/^[0-9]*$/)) {
      return setField('cep', number)
    }
    return
  }

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <form onSubmit={submitForm} className={classes.paper}>
          <div className={classes.avatar}>
            <img src={NeuroLogo} alt='' className={classes.logo} />
          </div>
          <Typography component='h1' variant='h5'>
            Algumas informações estão faltantes no seu cadastro. Preencha abaixo
            por favor.
          </Typography>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={cpf}
            onChange={e => setField('cpf', cpfMask(e.target.value))}
            onBlur={() => checkCPF()}
            id='cpf'
            label='CPF'
            name='cpf'
            autoComplete='cpf'
            error={validators.cpf.error}
            helperText={validators.cpf.msg}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={phone}
            onChange={e => setField('phone', phoneMask(e.target.value))}
            onBlur={() => checkPhone()}
            placeholder='Digite somente números com DDD de 2 dígitos'
            id='phone'
            label='Telefone'
            name='phone'
            autoComplete='phone'
            error={validators.phone.error}
            helperText={validators.phone.msg}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={password}
            onChange={e => setField('password', e.target.value)}
            onBlur={() => checkPassword()}
            helperText={validators.password.msg}
            error={validators.password.error}
            id='password'
            label='Sua senha'
            type='password'
            name='password'
            autoComplete='current-password'
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={password2}
            onChange={e => setField('password2', e.target.value)}
            onBlur={() => checkPassword()}
            helperText={validators.password.msg}
            error={validators.password.error}
            id='password2'
            label='Digite novamente a sua senha'
            name='password2'
            type='password'
            autoComplete='current-password'
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={cep}
            onChange={e => setCEP(e.target.value)}
            onBlur={() => getAddress()}
            id='cep'
            label='CEP (Somente números)'
            name='cep'
            error={validators.cep.error}
            helperText={validators.cep.msg}
            inputProps={{ maxLength: 8 }}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={street}
            onChange={e => setField('street', e.target.value)}
            id='street'
            label='Logradouro'
            name='street'
            helperText={validators.street.msg}
            error={validators.street.error}
          />
          <div className={classes.addressDiv}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              value={number}
              onChange={e => setField('number', e.target.value)}
              id='number'
              label='Número'
              name='number'
              className={classes.halfWidth}
              helperText={validators.number.msg}
              error={validators.number.error}
            />
            <TextField
              variant='outlined'
              margin='normal'
              value={complement}
              onChange={e => setField('complement', e.target.value)}
              id='complement'
              label='Complemento'
              name='complement'
              className={classes.halfWidth}
            />
          </div>
          <TextField
            variant='outlined'
            margin='normal'
            fullWidth
            required
            value={neighborhood}
            onChange={e => setField('neighborhood', e.target.value)}
            id='neighborhood'
            label='Bairro'
            name='neighborhood'
            helperText={validators.neighborhood.msg}
            error={validators.neighborhood.error}
          />
          <div className={classes.addressDiv}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              value={city}
              onChange={e => setField('city', e.target.value)}
              id='addressCity'
              label='Cidade'
              name='city'
              className={classes.halfWidth}
              helperText={validators.city.msg}
              error={validators.city.error}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              value={state}
              onChange={e => setField('state', e.target.value)}
              id='state'
              label='Estado'
              name='state'
              className={classes.halfWidth}
              helperText={validators.state.msg}
              error={validators.state.error}
            />
          </div>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            CADASTRAR
          </Button>
        </form>
      </Grid>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Erro</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

CompleteRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CompleteRegistration)
